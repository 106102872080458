import {Routes, UrlMatchResult, UrlSegment} from '@angular/router';
import {PageResolver} from '@scpc/modules/common/resolvers/page.resolver';
import {GamesEmptyComponent} from '@scpc/modules/games-lobby/components/games-empty/games-empty.component';
import {SportsLobbyComponent} from '@scpc/modules/sports/pages/lobby/sports-lobby.component';
import {ProductGuard} from '@scpc/modules/common/guards/product.guard';

export function categoryPageMatcher(url: UrlSegment[]): UrlMatchResult | null {
  if (url.length === 0) {
    return {consumed: url, posParams: {id: new UrlSegment('all-live', {})}};
  } else if (url.length === 1) {
    return {consumed: url, posParams: {id: new UrlSegment(url[0].path, {})}};
  }
  return null;
}

export function eventsTypesMatcher(url: UrlSegment[]): UrlMatchResult | null {
  if (url.length === 3 && (url[0].path === 'prematch' || url[0].path === 'live')) {
    return {
      consumed: url,
      posParams: {
        type: new UrlSegment(url[0].path, {}),
        category: new UrlSegment(url[1].path, {}),
        tournament: new UrlSegment(url[2].path, {}),
      },
    };
  } else if (url.length === 2 && (url[0].path === 'prematch' || url[0].path === 'live')) {
    return {
      consumed: url,
      posParams: {
        type: new UrlSegment(url[0].path, {}),
        category: new UrlSegment(url[1].path, {}),
      },
    };
  } else if (url.length >= 1 && url.length <= 2 && url[0].path === 'live') {
    return {
      consumed: url,
      posParams: {
        type: new UrlSegment(url[0].path, {}),
        category: new UrlSegment('live', {}),
      },
    };
  } else if (url.length === 2 && url[0].path === 'events') {
    return {consumed: url, posParams: {slug: new UrlSegment(url[1].path, {})}};
  } else if (url.length === 0) {
    return {consumed: url, posParams: {}};
  }
  return null;
}

export const ROUTES: Routes = [
  {
    matcher: eventsTypesMatcher,
    data: {product: 'SPORTS', ignoreSEO: true},
    component: SportsLobbyComponent,
    canActivate: [ProductGuard],
    children: [
      {
        matcher: categoryPageMatcher,
        data: {fullWidth: true, product: 'SPORTS', hasFooter: false, ignoreSEO: true},
        resolve: {seo: PageResolver},
        component: GamesEmptyComponent,
        runGuardsAndResolvers: (): boolean => false,
      },
    ],
  }
];
