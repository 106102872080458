"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Stack = void 0;
class Stack {
  constructor() {
    this._size = 0;
  }
  push(...values) {
    for (const value of values) {
      this.head = {
        value,
        next: this.head
      };
      this._size++;
    }
    return this;
  }
  pop(_default) {
    if (this.head) {
      const value = this.head.value;
      this.head = this.head.next;
      this._size--;
      return value;
    }
    return _default;
  }
  top() {
    return this.head.value;
  }
  isEmpty() {
    return this.head === undefined;
  }
  clear() {
    this.head = undefined;
    this._size = 0;
  }
  get size() {
    return this._size;
  }
}
exports.Stack = Stack;