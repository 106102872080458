<div class='scp-sports-event' [class.no-results]='!groups?.length'>
  @if (!event) {
    <div>
      <div class='scp-sports-event__row'>
        <button mat-ripple class='scp-sports-event__back' (click)='back.next()'>
          <img src='/assets/images/svg/back.svg' width='16' height='16' alt='' loading='lazy'/>
        </button>
        <div class='scp-sports-event__skeleton__tournament ssc-square without-border'></div>
      </div>
      <div class='scp-sports-event__options'>
        <div class='ssc-square without-border'></div>
      </div>
      <scp-sports-event-row-skeleton [isBrowser]='isBrowser' [markets]='false'></scp-sports-event-row-skeleton>
    </div>
  }
  @if (event) {
    <div>
      <div class='scp-sports-event__row'>
        <button mat-ripple class='scp-sports-event__back' (click)='back.next()' aria-label='Back'>
          <img src='/assets/images/svg/back.svg' width='16' height='16' alt='' loading='lazy'/>
        </button>
        <scp-sports-tournament-icon [sport]='event.sport'
                                    [category]='event.category'
                                    [tournament]='event.tournament'>
        </scp-sports-tournament-icon>
      </div>
      @if (segments.length > 1) {
        <div class='scp-sports-event__options'>
          <scp-segmented [segments]='segments'
                         [active]='segment'
                         (change)='onOptionChange($event)'>
          </scp-segmented>
        </div>
      }
      @if (!segment || segment?.value === "EVENT") {
        <scp-sports-event-card
          [event]='event'
          [tracker]='false'
          [background]='true'
          [responsive]='false'>
        </scp-sports-event-card>
      }
    </div>
  }
  <div (scpVisibility)='isSticky = !$event;'></div>
  @if (segment?.value === "TRACKER") {
    <div #trackerRef></div>
  }
  @if (segment?.value === "STATISTICS") {
    <div #statisticsRef></div>
  }
  @if (segment?.value === "VIDEO") {
    <scp-sports-event-video [event]="event"
                            [customer]="storageService.getCustomer() | async"
                            [isAuthorized]="isAuthorized"
                            (signIn)="authenticationService.setInterruptedUrl(router.url)">
    </scp-sports-event-video>
  }
  <div class='scp-sports-event__groups-wrapper' [class.is-sticky]='isSticky && isUp'>
    @if (initialLoading) {
      <div class='scp-sports-event__groups ssc-square without-border'></div>
    }
    <div class='scp-sports-event__groups'
         [class.hidden]='!groups?.length || initialLoading || (event && (event.status === "Finished"  || event.status === "Ended") )'>
      @if (!reachBeginning) {
        <div class='scp-sports-event__groups__arrow scp-sports-event__groups__arrow__left'>
          <button
            mat-icon-button
            (click)='swiper.slidePrev()'
            [attr.aria-label]="'BUTTON.PREV_PAGE' | translate">
            <img src='/assets/images/svg/arrow-2.svg' alt=''>
          </button>
        </div>
      }
      <div class='swiper swiper-markets-groups'>
        <div class='swiper-wrapper'>
          @for (group of groups; track group.id; let i = $index) {
            @if (group.id !== currentGroup.id) {
              <button class='swiper-slide'
                      (click)='showMarketsByGroup(group, false, true)'>
                <span> {{ group.name.toUpperCase() }}</span>
              </button>
            } @else {
              <h1 class='swiper-slide active'>{{ group.name.toUpperCase() }}</h1>
            }
          }
        </div>
      </div>
      @if (!reachEnd) {
        <div class='scp-sports-event__groups__arrow scp-sports-event__groups__arrow__right'>
          <button
            mat-icon-button
            (click)='swiper.slideNext()'
            [attr.aria-label]="'BUTTON.NEXT_PAGE' | translate">
            <img src='/assets/images/svg/arrow-2.svg' alt=''>
          </button>
        </div>
      }
      <div class='scp-sports-event__groups__border'></div>
    </div>
  </div>
  <div class='fixed' style='margin-top: -48px; padding-top: 48px'></div>
  @if (isAllLoading || initialLoading) {
    @for (i of ' '.repeat(10).split(''); track $index) {
      <div class='scp-sports-event__skeleton__market ssc-square without-border'></div>
    }
  }
  @if (groups?.length || initialLoading || (event && !ended)) {
    <div
      class='scp-sports-event__markets'>
      @if ((markets.size || isAllLoading || initialLoading) && !ended) {
        <cdk-accordion [multi]='true'>
          @for (group of markets.entries(); track marketsTrackBy(group)) {
            <cdk-accordion-item
              #accordionItem='cdkAccordionItem'
              class='scp-sports-event__market'
              [expanded]='group[1].expanded'
              [attr.aria-expanded]='accordionItem.expanded'>
              <div (click)='accordionItem.toggle(); updateState(group[0], accordionItem.expanded)'>
                {{ group[1].markets[0].name }}
                <img src='/assets/images/svg/arrow-3.svg'
                     alt=''
                     [class.expanded]='accordionItem.expanded'
                     width='24'
                     height='24'>
              </div>
              @if (accordionItem.expanded) {
                @for (market of group[1].markets; track marketTrackBy(market)) {
                  <div class='scp-sports-event__markets__outcomes id-{{market.id}}'
                       [class.not-available]='group[1].disabled'>
                    @if (["45", "80", "81", "98", "118", "121", "199", "897"].includes(market.id)) {
                      @if (formatCorrectScoreOutcomes(market); as formattedOutcomes) {
                        <div>
                          @if (formattedOutcomes.a.length) {
                            <div style='display: flex; flex-direction: column;'>
                              @for (outcome of formattedOutcomes.a; track outcomeTrackBy(outcome)) {
                                <button [disabled]='!outcome.active'
                                        [class.selected]='selected.has(outcome.combinedId) || creating.has(outcome.combinedId) || deleting.has(outcome.combinedId)'
                                        [class.processing]='creating.has(outcome.combinedId) || deleting.has(outcome.combinedId)'
                                        (click)='addToCard(market, outcome)'
                                        class='scp-sports-event__markets__outcome id-{{outcome.id}}'>
                                  <span>{{ outcome.name }}</span>
                                  <span class='odds'
                                        [class.up]='outcome.odds > outcome.oldOdds'
                                        [class.down]='outcome.odds < outcome.oldOdds'>
                                {{ outcome.odds | odds }}
                              </span>
                                </button>
                              }
                            </div>
                          }
                          @if (formattedOutcomes.b.length) {
                            <div style='display: flex; flex-direction: column;'>
                              @for (outcome of formattedOutcomes.b; track outcomeTrackBy(outcome)) {
                                <button [disabled]='!outcome.active'
                                        [class.selected]='selected.has(outcome.combinedId) || creating.has(outcome.combinedId) || deleting.has(outcome.combinedId)'
                                        [class.processing]='creating.has(outcome.combinedId) || deleting.has(outcome.combinedId)'
                                        (click)='addToCard(market, outcome)'
                                        class='scp-sports-event__markets__outcome id-{{outcome.id}}'>
                                  <span>{{ outcome.name }}</span>
                                  <span class='odds'
                                        [class.up]='outcome.odds > outcome.oldOdds'
                                        [class.down]='outcome.odds < outcome.oldOdds'>
                                {{ outcome.odds | odds }}
                              </span>
                                </button>
                              }
                            </div>
                          }
                          @if (formattedOutcomes.c.length) {
                            <div style='display: flex; flex-direction: column;'>
                              @for (outcome of formattedOutcomes.c; track outcomeTrackBy(outcome)) {
                                <button [disabled]='!outcome.active'
                                        [class.selected]='selected.has(outcome.combinedId) || creating.has(outcome.combinedId) || deleting.has(outcome.combinedId)'
                                        [class.processing]='creating.has(outcome.combinedId) || deleting.has(outcome.combinedId)'
                                        (click)='addToCard(market, outcome)'
                                        class='scp-sports-event__markets__outcome id-{{outcome.id}}'>
                                  <span>{{ outcome.name }}</span>
                                  <span class='odds'
                                        [class.up]='outcome.odds > outcome.oldOdds'
                                        [class.down]='outcome.odds < outcome.oldOdds'>
                                {{ outcome.odds | odds }}
                              </span>
                                </button>
                              }
                            </div>
                          }
                        </div>
                      }
                    } @else {
                      @for (outcome of market.outcomes; track outcomeTrackBy(outcome); ) {
                        <button [disabled]='!outcome.active'
                                [class.selected]='selected.has(outcome.combinedId) || creating.has(outcome.combinedId) || deleting.has(outcome.combinedId)'
                                [class.processing]='creating.has(outcome.combinedId) || deleting.has(outcome.combinedId)'
                                (click)='addToCard(market, outcome)'
                                class='scp-sports-event__markets__outcome id-{{ outcome.id }}'>
                          <span>{{ outcome.name }}</span>
                          <span class='odds'
                                [class.up]='outcome.odds > outcome.oldOdds'
                                [class.down]='outcome.odds < outcome.oldOdds'>{{ outcome.odds | odds }}
                          </span>
                        </button>
                      }
                    }
                  </div>
                }
              }
            </cdk-accordion-item>
          }
        </cdk-accordion>
      } @else if (event && !ended) {
        <scp-no-results
          icon='no-promotions'
          width='92'
          height='70'
          text='Sorry, no markets are active. Try to wait for a couple of minutes or select another event'>
        </scp-no-results>
      } @else if (event && ended) {
        <scp-no-results
          icon='ended'
          width='74'
          height='74'
          [invert]='false'
          title='Match is over'
          text='The current match is over, you can select similar matches that are currently available'
          url='/sports/live'
          urlText='Go to All live'>
        </scp-no-results>
      }
    </div>
  } @else if (event && !ended) {
    <scp-no-results
      icon='no-promotions'
      width='92'
      height='70'
      text='Sorry, no markets are active. Try to wait for a couple of minutes or select another event'>
    </scp-no-results>
  } @else if (event && ended) {
    <scp-no-results
      icon='ended'
      width='74'
      height='74'
      [invert]='false'
      title='Match is over'
      text='The current match is over, you can select similar matches that are currently available'
      url='/sports/live'
      urlText='Go to All live'>
    </scp-no-results>
  }
</div>
