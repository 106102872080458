"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SortedMap = void 0;
const array_1 = require("../array");
class SortedMap {
  constructor(comparator) {
    this.comparator = comparator;
    this.array = new array_1.SortedArray((a, b) => comparator(a.key, b.key), true);
  }
  has(key) {
    return this.array.includes({
      key: key,
      value: undefined
    });
  }
  get(key, _default) {
    var _a, _b;
    const index = this.array.firstIndexOf({
      key: key,
      value: undefined
    });
    return (_b = (_a = this.array.get(index)) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : _default;
  }
  set(key, value) {
    this.array.push({
      key,
      value
    });
    return this;
  }
  delete(key) {
    return this.array.delete({
      key: key,
      value: undefined
    });
  }
  keys() {
    return this.array.toArray().map(n => n.key)[Symbol.iterator]();
  }
  values() {
    return this.array.toArray().map(n => n.value)[Symbol.iterator]();
  }
  entries() {
    return this.array.toArray().map(n => [n.key, n.value])[Symbol.iterator]();
  }
  clear() {
    this.array.clear();
  }
  [Symbol.iterator]() {
    return this.entries();
  }
  forEach(cb, thisArg) {
    this.array.toArray().forEach(item => cb.call(thisArg, item.value, item.key, this));
  }
  get size() {
    return this.array.length;
  }
}
exports.SortedMap = SortedMap;