import { Inject, Injectable } from '@angular/core';
import { Observable, merge, fromEvent } from 'rxjs';
import { share, tap } from 'rxjs/operators';
import { WindowRuler } from './viewport-ruler';
import { WindowElement } from './models';
import { DOCUMENT } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class ScrollObservable {

  private static globalObservable: Observable<any>;
  private static elementObservableReferences: Map<WindowElement, Observable<any>> = new Map();

  constructor(private windowRuler: WindowRuler,
              @Inject(DOCUMENT) private document: Document) {
  }

  public static isWindow(windowElement: WindowElement) {
    return Object.prototype.toString.call(windowElement).includes('Window');
  }

  public scrollObservableFor(windowElement: WindowElement): Observable<any> {
    if (ScrollObservable.isWindow(windowElement)) {
      if (!ScrollObservable.globalObservable) {
        ScrollObservable.globalObservable = this.getGlobalObservable();
      }
      return ScrollObservable.globalObservable;
    }
    if (ScrollObservable.elementObservableReferences.has(windowElement)) {
      return ScrollObservable.elementObservableReferences.get(windowElement) as Observable<any>;
    }
    const ref = this.createElementObservable(windowElement);
    ScrollObservable.elementObservableReferences.set(windowElement, ref);
    return ref;
  }

  private createElementObservable(windowElement: WindowElement): Observable<any> {
    return fromEvent(windowElement, 'scroll').pipe(share());
  }

  private getGlobalObservable(): Observable<any> {
    return merge(
      fromEvent(this.document, 'scroll'),
      fromEvent(this.document.defaultView, 'resize'),
    ).pipe(
      tap(/* istanbul ignore next */() => this.windowRuler.onChange()),
      share(),
    );
  }

}
