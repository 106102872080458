import {
  ChangeDetectionStrategy,
  Component, inject, PLATFORM_ID,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import {
  EventRowSkeletonComponent,
} from '@scpc/modules/sports/components/event-row-skeleton/event-row-skeleton.component';

@Component({
  selector: 'scp-sports-events-skeleton',
  templateUrl: './events-skeleton.component.html',
  styleUrls: ['./events-skeleton.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    EventRowSkeletonComponent,
  ],
})
export class EventsSkeletonComponent {

  protected isBrowser = isPlatformBrowser(inject(PLATFORM_ID));

}
