import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { CdkAccordion, CdkAccordionItem } from '@angular/cdk/accordion';
import { MatCheckbox } from '@angular/material/checkbox';
import { EventCategory, EventSport, EventTopCategory, EventTournament, EventType } from '@scpc/modules/sports/dto';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SportImagePipe } from '@scpc/modules/sports/pipes/sport-image';
import { CategoryImagePipe } from '@scpc/modules/sports/pipes/category-image';
import { DefaultCategoryImage } from '@scpc/modules/sports/pipes/default-category-image';
import { ImgFallbackDirective } from '@scpc/modules/common/directives/img-fallback.derictive';
import { FilterRangeComponent } from '@scpc/modules/sports/components/filter-range/filter-range.component';

@Component({
  selector: 'scp-sports-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CdkAccordion,
    CdkAccordionItem,
    MatCheckbox,
    RouterLink,
    TranslateModule,
    SportImagePipe,
    CategoryImagePipe,
    ImgFallbackDirective,
    DefaultCategoryImage,
    FilterRangeComponent,
  ],
})
export class FilterComponent implements OnChanges {

  @Input()
  public type: EventType;

  @Input()
  public border = true;

  @Input()
  public close = false;

  @Input()
  public sports: EventSport[];

  @Input()
  public categories: EventCategory[];

  @Input()
  public topCategories: EventTopCategory[];

  @Input()
  public category: string;

  @Input()
  public sport: EventSport;

  @Input()
  public defaultCategory: string;

  @Input()
  public range: number;

  @Input()
  public hasLive: boolean;

  @Input()
  public tournaments: string[];

  @Input()
  public loading = false;

  @Input()
  public event = false;

  @Output()
  public closed: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public selectionChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  @ViewChild('scrollbar', { read: ElementRef, static: true })
  public scrollbar: ElementRef<HTMLDivElement>;

  protected model: SelectionModel<string> = new SelectionModel(true, []);
  protected sportSlug: string;

  constructor(private readonly changeDetectorRef: ChangeDetectorRef,
              private readonly zone: NgZone) {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('sports' in changes || 'categories' in changes || 'topCategories' in changes) {
      /* istanbul ignore next */
      if (changes.sports && changes.sports.currentValue?.length === changes.sports.previousValue?.length && changes.sports.currentValue?.length > 0) {
        return;
      }
      this.updateSelectedTournaments();
    }
    if ('tournaments' in changes || 'sport' in changes) {
      this.updateSelectedTournaments();
    }
    if ('category' in changes) {
      /* istanbul ignore next */
      this.sportSlug = this.category?.replace('_', ' ').replace('-', ' ').toLowerCase();
    }
  }

  public getSelection() {
    return this.model.selected;
  }

  /* istanbul ignore next */
  public update(): void {
    this.zone.run(() => this.changeDetectorRef.detectChanges());
  }

  protected toggleCategory(item: CdkAccordionItem) {
    item.toggle();
  }

  protected toggleTournament(id: string): void {
    this.model.toggle(id);
    this.selectionChange.emit(this.model.selected);
  }

  protected clear(): void {
    this.model.clear();
    this.selectionChange.emit(this.model.selected);
  }

  protected trackBySport(sport: EventSport): string {
    return sport.slug + sport.priority;
  }


  protected trackByTopCategory(category: EventTopCategory): string {
    return category.category.id + category.tournament.id + category.tournament.priority;
  }

  protected trackByTournament(tournament: EventTournament): string {
    return tournament.id + tournament.priority;
  }

  private updateSelectedTournaments(): void {
    this.model.clear();
    if (this.tournaments?.length) {
      const set = new Set([
        ...(this.categories || []).flatMap((c: EventCategory): string[] => c.tournaments.map(t => t.id)),
        ...(this.topCategories || []).map((t: EventTopCategory): string => t.tournament.id),
        ...(this.sports || []).flatMap((s: EventSport): string[] => s.tournaments.map(t => t.tournament.id)),
      ]);
      this.model.select(...this.tournaments.filter((t: string): boolean => set.has(t)));
    } else {
      this.selectionChange.emit(this.model.selected);
    }
  }

}
