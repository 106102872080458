import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
} from '@angular/core';
import { Swiper } from 'swiper';
import { FreeMode } from 'swiper/modules';
import { catchError } from '@scpc/utils/dom.utils';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconButton } from '@angular/material/button';
import { Router } from '@angular/router';
import { EventSport, EventType } from '@scpc/modules/sports/dto';

@Component({
  selector: 'scp-sports-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TranslateModule,
    MatIconButton,
  ],
})
export class MenuComponent implements AfterViewInit, OnDestroy {

  @Input()
  public sports: EventSport[];

  @Input()
  public type: EventType;

  @Input()
  public range: number;

  @Input()
  public category: string;

  @Input()
  public isBrowser: boolean;

  protected reachBeginning: boolean = true;
  protected reachEnd: boolean = true;
  protected swiper: Swiper;

  constructor(private readonly router: Router,
              private readonly zone: NgZone,
              private readonly elementRef: ElementRef,
              private readonly changeDetectorRef: ChangeDetectorRef) {
  }

  public ngAfterViewInit() {
    this.swiper = new Swiper('.swiper-sports', {
      init: this.isBrowser,
      freeMode: {
        enabled: true,
        momentum: true,
      },
      modules: [FreeMode],
      initialSlide: this.sports.findIndex((sport: EventSport) => sport.slug === this.category) || 0,
      touchRatio: 1.5,
      speed: 400,
      resistance: true,
      resistanceRatio: 0.75,
      slidesPerView: 'auto',
      watchSlidesProgress: true,
      resizeObserver: true,
      updateOnWindowResize: true,
      centeredSlides: true,
      centeredSlidesBounds: true,
      centerInsufficientSlides: false,
      on: {
        afterInit:  /* istanbul ignore next */(swiper: Swiper) => this.updateSlider(swiper),
        reachBeginning:  /* istanbul ignore next */ (swiper: Swiper) => this.updateSlider(swiper),
        reachEnd:  /* istanbul ignore next */ (swiper: Swiper) => this.updateSlider(swiper),
        resize:  /* istanbul ignore next */ (swiper: Swiper) => this.updateSlider(swiper),
        setTranslate:  /* istanbul ignore next */ (swiper: Swiper, translate: number) => {
          if (!Number.isNaN(translate)) {
            this.updateSlider(swiper);
          }
        },
      },
    });
  }

  public ngOnDestroy() {
    catchError(() => this.swiper.destroy());
  }

  public update() {
    this.changeDetectorRef.detectChanges();
    /* istanbul ignore next */
    catchError(() => this.swiper.update());
  }

  protected trackBy(sport: EventSport): string {
    return `${sport.id} ${sport.liveCount} ${sport.priority}`;
  }

  protected async navigate(index: number): Promise<void> {
    const sport = [...this.sports][index];
    await this.router.navigate([!sport.slug
      ? '/sports'
      : sport.slug === 'live'
        ? '/sports/live'
        : sport.liveCount && (this.type === 'live' || !this.type)
          ? '/sports/live/' + sport.slug
          : '/sports/prematch/' + sport.slug], {
      queryParams: {
        range: sport.slug && sport.slug !== 'live' && this.type === 'prematch' && this.range ? this.range : null,
        tournament: null,
      },
      queryParamsHandling: 'merge',
    });
    this.swiper.slideTo(index);
  }

  /* istanbul ignore next */
  private updateSlider(swiper: Swiper) {
    const hasOverflow: boolean = Math.round((swiper as any).virtualSize) > swiper.width;
    this.reachBeginning = swiper.isBeginning || !hasOverflow;
    this.reachEnd = swiper.isEnd || !hasOverflow;
    this.zone.run(() => this.changeDetectorRef.markForCheck());
  }

}
