import { Injectable } from '@angular/core';

export class WindowRulerStatic {

  private static windowRect: DOMRect;

  public static onChange() {
    this.windowRect = this.createWindowRect();
  }

  public static getWindowViewPortRuler(): DOMRect {
    return this.windowRect;
  }

  private static createWindowRect(): DOMRect {
    const height: number = typeof window !== 'undefined' ? window.innerHeight : 0;
    const width: number = typeof window !== 'undefined' ? window.innerWidth : 0;
    return {
      top: 0,
      left: 0,
      bottom: height,
      right: width,
      height,
      width,
    } as DOMRect;
  }

}

@Injectable({ providedIn: 'root' })
export class WindowRuler {

  constructor() {
    WindowRulerStatic.onChange();
  }

  public onChange(): void {
    WindowRulerStatic.onChange();
  }

  public getWindowViewPortRuler(): DOMRect {
    return WindowRulerStatic.getWindowViewPortRuler();
  }

}
