import { Pipe, PipeTransform } from '@angular/core';
import { EventSport } from '@scpc/modules/sports/dto';

@Pipe({
  name: 'defaultCategoryImage',
})
export class DefaultCategoryImage implements PipeTransform {

  public transform(sport: EventSport) {
    return `/assets/common/sports/categories/default-category-${sport.id.replace(/:/g, '-')}.png`;
  }

}

