"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LruSet = void 0;
const map_1 = require("../map");
class LruSet {
  constructor(limit) {
    this.map = new map_1.LruMap(limit);
  }
  has(value) {
    return this.map.has(value);
  }
  add(value) {
    this.map.set(value, value);
    return this;
  }
  delete(value) {
    return this.map.delete(value);
  }
  keys() {
    return this.map.keys();
  }
  values() {
    return this.map.values();
  }
  entries() {
    return this.map.entries();
  }
  clear() {
    this.map.clear();
  }
  [Symbol.iterator]() {
    return this.values();
  }
  forEach(cb, thisArg) {
    this.map.forEach(item => cb.call(thisArg, item, item, this));
  }
  get size() {
    return this.map.size;
  }
}
exports.LruSet = LruSet;