import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { VisibilityService } from '@scpc/modules/common/services/visibility.service';

@Component({
  selector: 'scp-sports-event-row-skeleton',
  templateUrl: './event-row-skeleton.component.html',
  styleUrls: ['./event-row-skeleton.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventRowSkeletonComponent implements OnInit, OnDestroy {

  @Input()
  public isBrowser = false;

  @Input()
  public markets = true;

  @Input()
  public season = false;

  public visible = false;

  constructor(private readonly elementRef: ElementRef,
              public readonly changeDetectorRef: ChangeDetectorRef,
              private readonly zone: NgZone,
              private readonly visibilityService: VisibilityService) {
  }

  public ngOnInit() {
    if (this.isBrowser) {
      this.visibilityService.observe(this.elementRef.nativeElement,  /* istanbul ignore next */(isVisible: boolean): void => {
        this.visible = isVisible;
        this.zone.run(() => this.changeDetectorRef.detectChanges());
      });
    }
  }

  public ngOnDestroy() {
    if (this.isBrowser) {
      this.visibilityService.unobserve(this.elementRef.nativeElement);
    }
  }

}
