"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SizedMap = void 0;
class SizedMap {
  constructor(limit) {
    if (limit < 0) {
      throw new Error('Map size limit should positive.');
    }
    this.limit = limit;
    this.map = new Map();
  }
  delete(key) {
    return this.map.delete(key);
  }
  keys() {
    return this.map.keys();
  }
  values() {
    return [...this.map.values()].map(node => node.value)[Symbol.iterator]();
  }
  entries() {
    return [...this.map.values()].map(node => [node.key, node.value])[Symbol.iterator]();
  }
  isFull() {
    return this.size === this.limit;
  }
  clear() {
    this.map.clear();
  }
  [Symbol.iterator]() {
    return this.entries();
  }
  forEach(cb, thisArg) {
    this.map.forEach(item => cb.call(thisArg, item.value, item.key, this));
  }
  get size() {
    return this.map.size;
  }
}
exports.SizedMap = SizedMap;