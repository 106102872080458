"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SortedSet = void 0;
const array_1 = require("../array");
class SortedSet {
  constructor(comparator) {
    this.array = new array_1.SortedArray(comparator, true);
  }
  has(value) {
    return this.array.includes(value);
  }
  add(...values) {
    this.array.push(...values);
    return this;
  }
  delete(value) {
    return this.array.delete(value);
  }
  keys() {
    return this.array.toArray()[Symbol.iterator]();
  }
  values() {
    return this.array.toArray()[Symbol.iterator]();
  }
  entries() {
    return this.array.toArray().map(value => [value, value])[Symbol.iterator]();
  }
  clear() {
    this.array.clear();
  }
  [Symbol.iterator]() {
    return this.values();
  }
  forEach(cb, thisArg) {
    this.array.toArray().forEach(item => cb.call(thisArg, item, item, this));
  }
  get size() {
    return this.array.length;
  }
}
exports.SortedSet = SortedSet;