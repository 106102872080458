<div class='scp-sports-lobby' [class.no-results]='empty'>
  <div class='scp-sports-lobby__tournaments'>
    <scp-sports-filter #filter
                       [type]='type'
                       [event]='event && eventEnded'
                       [sport]='sport || event?.sport'
                       [category]='category'
                       [defaultCategory]='defaultSport?.slug || "soccer"'
                       [range]='range'
                       [topCategories]='filterTopCategories'
                       [categories]='filterCategories'
                       [sports]='filterSports'
                       [hasLive]='sport?.liveCount > 0 || hasLive'
                       [tournaments]='tournaments'
                       [loading]='loading'
                       (selectionChange)='updateSelectedTournaments($event, true)'>
    </scp-sports-filter>
  </div>
  <div class='scp-sports-lobby__working_area'>
    <div class='scp-sports-lobby__breadcrumbs search'>
      <scp-breadcrumbs [items]='breadcrumbs'
                       [loading]='!(!loading || sports?.length)'>
      </scp-breadcrumbs>
      <scp-search [isBrowser]='isBrowser' [product]='"sp"'></scp-search>
    </div>
    @if (!slug) {
      @for (item of items; track item.type) {
        <scp-lobby-item [isAuthorized]='isAuthorized'
                        [title]='item.title'
                        [link]='item.link'
                        [class]='item.type'
                        [onlyForAuthorized]='item.type === "RECENTLY_PLAYED_GAMES_ITEM" || item.type === "BONUSES_ITEM"'>
          @switch (item.type) {
            @case ('BANNERS_ITEM') {
              <scp-banner [slides]='item.banners'
                          [isAuthorized]='isAuthorized'
                          [isFTD]='(storageService.getCustomer() | async)?.isFTD || false'>
              </scp-banner>
            }
          }
        </scp-lobby-item>
      }
      <div (scpVisibility)='isSticky = !$event;'></div>
      <div class='scp-sports-menu-sticky' [class.is-sticky]='isSticky && isUp'>
        @if (loading && !sports?.length) {
          <div class='scp-sports-menu-preloader ssc-square'></div>
        } @else if (!loading || sports?.length) {
          <scp-sports-menu [sports]='sports'
                           [type]='type'
                           [category]='category'
                           [range]='range'
                           [isBrowser]='isBrowser'>
          </scp-sports-menu>
        }
        <scp-sports-filter-mobile #filterMobile
                                  [type]='type'
                                  [sport]='sport || event?.sport'
                                  [category]='category'
                                  [defaultCategory]='defaultSport?.slug || "soccer"'
                                  [range]='range'
                                  [topCategories]='filterTopCategories'
                                  [categories]='filterCategories'
                                  [sports]='filterSports'
                                  [hasLive]='sport?.liveCount > 0'
                                  [tournaments]='tournaments'
                                  [loading]='loading'
                                  (selectionChange)='updateSelectedTournaments($event, false)'>
        </scp-sports-filter-mobile>
      </div>
      <div class='fixed' id='fixed'></div>
      @if (category && category !== "live") {
        <div class='scp-sports-lobby__events'>
          @if (sport && !loading) {
            @if (schema) {
              <ngx-json-ld [json]='schema'></ngx-json-ld>
            }
            @for (tournaments of eventsBySport.get(sport)!; track trackByTournament(tournaments)) {
              @if (tournaments[0] && tournaments[1]?.length && (tournamentsCache.get(tournaments[0].id)?.tournament || tournaments[1][0].event?.tournament)) {
                <scp-sports-events #comp
                                   scpInView
                                   [id]='tournaments[0].id'
                                   [isBrowser]='isBrowser'
                                   [items]='tournaments[1]!'
                                   [sport]='sport'
                                   [category]='tournamentsCache.get(tournaments[0].id)?.category || tournaments[1][0].event?.category'
                                   [tournament]='tournamentsCache.get(tournaments[0].id)?.tournament || tournaments[1][0].event?.tournament'
                                   [scrollElement]='scroll'
                                   (inView)='inView($event, sport, comp)'
                                   [throttle]='30'
                                   [trigger]='trigger'
                                   [triggerOnInit]='true'
                                   [offset]='[2000, 0, 2000, 0]'
                                   [source]='source'>
                </scp-sports-events>
              }
            }
          }
          @if (loading || loadingTournaments) {
            <scp-sports-events-skeleton></scp-sports-events-skeleton>
          }
          @if (empty && !loading) {
            <scp-no-results icon='no-promotions'
                            [url]='noResultsURL'
                            [urlText]='noResultsText'
                            width='92'
                            height='70'
                            text='Oh no, it appears that there are currently no events during this time. Try changing your filter to search other events.'>
            </scp-no-results>
          }
        </div>
      }
      @if (!category || category === "live") {
        <div class='scp-sports-lobby__events'>
          @if (eventsBySport.size) {
            <cdk-accordion class='scp-sports-lobby__events__by-sport' [multi]='true'>
              @for (sport of eventsBySport.keys(); track sport.id) {
                @if (eventsBySport.get(sport).length) {
                  <cdk-accordion-item [id]='sport.id'
                                      #accordionItem='cdkAccordionItem'
                                      role='button'
                                      tabindex='0'
                                      [expanded]='true'>
                    @if (category) {
                      <div class='scp-sports-lobby__events__by-sport__header expandable'
                           (click)='expandSport(accordionItem)'>
                        <img [src]='sport.id | sportImage: false:true' alt='' loading='lazy'>
                        <div>{{ sport.name }}</div>
                        <img src='/assets/images/svg/menu-arrow-2.svg'
                             width='13'
                             height='8'
                             alt=''
                             [class.expanded]='accordionItem.expanded'>
                      </div>
                    }
                    @if (!category) {
                      <div class='scp-sports-lobby__events__by-sport__header'>
                        <img [src]='sport.id | sportImage: false:true' alt='' loading='lazy'>
                        <div>{{ sport.name }}</div>
                        <a routerLink='/sports/prematch/{{sport.slug}}'>{{ 'Future events' | translate }}</a>
                      </div>
                    }
                    <div class='scp-sports-lobby__events__by-sport__body'
                         [style.display]="accordionItem.expanded ? '' : 'none'">
                      @for (tournaments of eventsBySport.get(sport)!; track trackByTournament(tournaments)) {
                        @if (tournamentsCache.get(tournaments[0].id)?.tournament || tournaments[1][0].event?.tournament) {
                          <scp-sports-events #comp
                                             scpInView
                                             [id]='tournaments[0].id'
                                             [items]='tournaments[1]!'
                                             [sport]='sport'
                                             [isBrowser]='isBrowser'
                                             [category]='tournamentsCache.get(tournaments[0].id)?.category || tournaments[1][0].event?.category'
                                             [tournament]='tournamentsCache.get(tournaments[0].id)?.tournament || tournaments[1][0].event?.tournament'
                                             [scrollElement]='scroll'
                                             (inView)='inView($event, sport, comp)'
                                             [throttle]='0'
                                             [trigger]='trigger'
                                             [triggerOnInit]='true'
                                             [offset]='[2000, 0, 2000, 0]'
                                             [source]='source'>
                          </scp-sports-events>
                        }
                      }
                    </div>
                  </cdk-accordion-item>
                }
              }
            </cdk-accordion>
          }
          @if (loading || loadingTournaments) {
            <scp-sports-events-skeleton></scp-sports-events-skeleton>
          }
          @if (empty && !loading) {
            <scp-no-results icon='no-promotions'
                            [url]='noResultsURL'
                            [urlText]='noResultsText'
                            width='92'
                            height='70'
                            text='Oh no, it appears that there are currently no events during this time. Try changing your filter to search other events.'>
            </scp-no-results>
          }
        </div>
      }
    } @else {
      <div style='display: flex; max-width: 990px;'>
        <scp-sports-event [event]='event' (back)='back()'></scp-sports-event>
        <div></div>
      </div>
    }
    <scp-seo [seoText]='seoText' [loading]='loading'></scp-seo>
    @if (!event) {
      <scp-footer></scp-footer>
    }
  </div>
</div>
