"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Queue = void 0;
class Queue {
  constructor() {
    this._size = 0;
  }
  push(...values) {
    for (const value of values) {
      const node = {
        value
      };
      if (this.tail) {
        this.tail.next = node;
      }
      this.tail = node;
      if (!this.head) {
        this.head = this.tail;
      }
      this._size++;
    }
    return this;
  }
  pop(_default) {
    if (this.head) {
      const value = this.head.value;
      this.head = this.head.next;
      if (!this.head) {
        this.tail = undefined;
      }
      this._size--;
      return value;
    }
    return _default;
  }
  peek() {
    return this.head.value;
  }
  isEmpty() {
    return this.head === undefined;
  }
  clear() {
    this.head = this.tail = undefined;
    this._size = 0;
  }
  get size() {
    return this._size;
  }
}
exports.Queue = Queue;