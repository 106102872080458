import { EventCategory, EventSport, EventTournament, Event } from '@scpc/modules/sports/dto';
import { BreadcrumbItem } from '@scpc/modules/common/components/breadcrumbs/breadcrumbs';
import { isEventEnded } from '@scpc/modules/sports/utils/event';

export function getDefaultBreadcrumbs(): BreadcrumbItem[] {
  return [
    {
      name: '',
      icon: '/assets/common/top.svg',
      href: '/',
    },
    {
      name: 'Sports',
      href: '/sports',
    },
  ];
}

export function getAllLiveDefaultBreadcrumbs(): BreadcrumbItem[] {
  return [
    ...getDefaultBreadcrumbs(),
    {
      name: 'All live'
    },
  ];
}

export function sportToBreadcrumbItem(data: EventSport, type: 'live' | 'prematch'): BreadcrumbItem[] {
  return [
    ...getDefaultBreadcrumbs(),
    {
      name: data.name + (type === 'live' ? ' Live' : ''),
      href: `/sports/${type}/${data.slug}`,
    },
  ];
}

export function tournamentToBreadcrumbItems(data: {
  category: EventCategory,
  tournament: EventTournament,
  sport: EventSport
}, type: 'live' | 'prematch'): BreadcrumbItem[] {
  return [
    ...sportToBreadcrumbItem(data.sport, type),
    {
      name: `${data.category.name}. ${data.tournament.name}`,
      href: `/sports/${type}/${data.sport.slug}/${data.tournament.slug}`,
    },
  ];
}

export function eventToBreadcrumbItems(event: Event): BreadcrumbItem[] {
  return [
    ...tournamentToBreadcrumbItems({
      sport: event.sport,
      category: event.category,
      tournament: event.tournament,
    }, event.live && !isEventEnded(event) ? 'live' : 'prematch'),
    {
      name: event.season?.name ?? event.homeTeam.name + ' vs ' + event.awayTeam.name,
    },
  ];
}

