import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'categoryImage',
})
export class CategoryImagePipe implements PipeTransform {

  public transform(id: string) {
    return `/assets/common/sports/categories/${id.replace(/:/g, '-')}.png`;
  }

}

