<div class='scp-sports-event-row-skeleton' [class.shadow]='visible' [class.markets]='markets' [class.season]='season'>
  @if (visible && isBrowser) {
    <div class='scp-sports-event-row-skeleton__card ssc-square'></div>
    @if (markets) {
      <div class='scp-sports-event-row-skeleton__markets'>
        <div class='div1 ssc-square without-border'></div>
        <div class='div2 ssc-square without-border'></div>
        <div class='div3 ssc-square without-border'></div>
        <div class='div4 ssc-square'></div>
        <div class='div5 ssc-square'></div>
        <div class='div6 ssc-square'></div>
        <div class='div7 ssc-square'></div>
        <div class='div8 ssc-square'></div>
        <div class='div9 ssc-square'></div>
        <div class='div10 ssc-square'></div>
      </div>
    }
  }
</div>
