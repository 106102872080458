<div class='scp-sports-events' id='{{tournament?.id}}'>
  <scp-sports-tournament-icon [sport]='sport'
                              [category]='category'
                              [tournament]='tournament'>
  </scp-sports-tournament-icon>
  @for (item of items; track trackBy(item); ) {
    @if (item.event) {
      <scp-sports-event-row [id]='item.eventMeta.id'
                            [isBrowser]='isBrowser'
                            [event]='item.event'
                            [source]='source'>
      </scp-sports-event-row>
    } @else {
      <scp-sports-event-row-skeleton [id]='item.eventMeta.id'
                                     [isBrowser]='isBrowser'
                                     [season]='item.eventMeta.season'>
      </scp-sports-event-row-skeleton>
    }
  }
</div>
