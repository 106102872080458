<div class='scp-sports-filter' [class.border]='border'>
  <div class='scp-sports-filter__compact' [class.with-action]='close'>
    <scp-sports-filter-range [category]='category'
                             [defaultCategory]='defaultCategory'
                             [hasLive]='hasLive'
                             [range]='range'
                             [event]='event'
                             [type]='type'>
    </scp-sports-filter-range>
    @if (close) {
      <button (click)='closed.next()'>
        <img src='/assets/images/sports/close.svg' alt='' width='12' height='12'>
      </button>
    }
  </div>
  <div #scrollbar class='scroll'>
    @if (!category || category === "live" || (sports?.length && event)) {
      <cdk-accordion class='scp-sports-filter__accordion' [multi]='true'>
        <cdk-accordion-item #accordionItem='cdkAccordionItem'
                            class='scp-sports-filter__accordion__item disabled'
                            role='button'
                            [expanded]='true'
                            [disabled]='true'
                            tabindex='0'>
          <div class='scp-sports-filter__accordion__item__header single'>
            <a [routerLink]='category ? "/sports/live" : "/sports"'
               [queryParams]='{tournament: null}'
               [state]='{tournaments: true}'
               queryParamsHandling='merge'
               (click)='clear()'
               [class.active]='!model.selected.length'>
              <mat-checkbox color='primary'
                            [checked]='!model.selected.length && !event' class='s'
                            [disableRipple]='true'
                            [aria-label]='(category ? "S.R_L_A" :  "S.R_T_A") | translate'>
              </mat-checkbox>
              {{ (category ? 'S.R_L_A' : 'S.R_T_A')| translate }}
            </a>
          </div>
        </cdk-accordion-item>
        @for (sport of sports; track trackBySport(sport); ) {
          <cdk-accordion-item [expanded]='true'
                              #accordionItem='cdkAccordionItem'
                              class='scp-sports-filter__accordion__item'
                              role='button'
                              tabindex='0'
                              [attr.id]="'scp-sports-tournaments-filter-accordion-header-' + $index"
                              [attr.aria-expanded]='accordionItem.expanded'
                              [attr.aria-controls]="'scp-filter-accordion-body-' + $index">
            <div class='scp-sports-filter__accordion__item__header'
                 [class.expanded]='accordionItem.expanded'
                 (click)='toggleCategory(accordionItem)'>
              <img src='{{sport.id | sportImage: false: true}}' alt='' height='20' width='20' class='sport'
                   loading='lazy'>
              {{ sport.name }}
              <img class='scp-sports-filter__accordion__item__header__arrow'
                   src='/assets/images/svg/menu-arrow-2.svg'
                   alt='' height='8' width='13' loading='lazy'>
            </div>
            <div class='scp-sports-filter__accordion__item__body' role='region'
                 [style.display]="accordionItem.expanded ? '' : 'none'"
                 [attr.id]="'scp-filter-accordion-body-' + $index"
                 [attr.aria-labelledby]="'scp-sports-tournaments-filter-accordion-header-' + $index">
              @for (item of sport.tournaments; track trackByTopCategory(item)) {
                <mat-checkbox color='primary'
                              class='s'
                              [checked]='model.isSelected(item.tournament.id)'
                              [disabled]='model.selected.length === 20 && !model.isSelected(item.tournament.id)'
                              [disableRipple]='true'
                              (change)='toggleTournament(item.tournament.id)'>
                  {{ item.category.name + '. ' + item.tournament.name }}
                </mat-checkbox>
              }
            </div>
          </cdk-accordion-item>
        }
      </cdk-accordion>
    } @else if (category && category !== "live" && !sports?.length) {
      <cdk-accordion class='scp-sports-filter__accordion' [multi]='true'>
        <cdk-accordion-item #accordionItem='cdkAccordionItem'
                            class='scp-sports-filter__accordion__item disabled'
                            role='button'
                            [expanded]='true'
                            [disabled]='true'
                            tabindex='0'>
          <div class='scp-sports-filter__accordion__item__header single'>
            <a [routerLink]='[]'
               [queryParams]='{tournament: null}'
               [state]='{tournaments: true}'
               queryParamsHandling='merge'
               (click)='clear()'
               [class.active]='!model.selected.length'>
              <mat-checkbox class='s' color='primary' [checked]='!model.selected.length && !tournaments?.length'
                            [disableRipple]='true'></mat-checkbox>
              @if (range === 1) {
                {{ 'S.R_1_HOUR' | translate:{ range } }}
              } @else if (range > 1) {
                {{ 'S.R_N_HOURS' | translate:{ range } }}
              } @else if (!range && type === "prematch") {
                {{ 'S.R_P_A' | translate }}
              } @else if (!range && type === "live") {
                {{ 'S.R_L_S' | translate: { sport: sportSlug } }}
              }
            </a>
          </div>
          <div class='scp-sports-filter__accordion__item__body' role='region'>
            @for (top of topCategories; track trackByTopCategory(top)) {
              <mat-checkbox class='s'
                            color='primary'
                            [class]='top.tournament.id'
                            [checked]='model.isSelected(top.tournament.id)'
                            [disableRipple]='true'
                            [disabled]='model.selected.length === 20 && !model.isSelected(top.tournament.id)'
                            (change)='toggleTournament(top.tournament.id)'>
                {{ top.category.name + '. ' + top.tournament.name }}
              </mat-checkbox>
            }
          </div>
        </cdk-accordion-item>
        @for (category of categories; track category.id; ) {
          <cdk-accordion-item
            #accordionItem='cdkAccordionItem'
            class='scp-sports-filter__accordion__item {{category.id}}'
            role='button'
            tabindex='0'
            [attr.id]="'scp-sports-tournaments-filter-accordion-header-' + $index"
            [attr.aria-expanded]='accordionItem.expanded'
            [attr.aria-controls]="'scp-sports-tournaments-filter-accordion-body-' + $index">
            <div class='scp-sports-filter__accordion__item__header'
                 [class.expanded]='accordionItem.expanded'
                 (click)='toggleCategory(accordionItem)'>
              <picture>
                <img src='{{category.id | categoryImage}}' alt='' height='18' width='25' loading='lazy'
                     scpImageFallback='{{ sport | defaultCategoryImage }}'>
              </picture>
              {{ category.name }}
              <img src='/assets/images/svg/menu-arrow-2.svg' alt='' height='8' width='13' loading='lazy'>
            </div>
            @if (accordionItem.expanded) {
              <div class='scp-sports-filter__accordion__item__body' role='region'
                   [style.display]="accordionItem.expanded ? '' : 'none'"
                   [attr.id]="'scp-sports-tournaments-filter-accordion-body-' + $index"
                   [attr.aria-labelledby]="'scp-sports-tournaments-filter-accordion-header-' + $index">
                @for (tournament of category.tournaments; track trackByTournament(tournament)) {
                  <mat-checkbox color='primary'
                                class='s'
                                [class]='tournament.id'
                                [checked]='model.isSelected(tournament.id)'
                                [disableRipple]='true'
                                [disabled]='model.selected.length === 20 && !model.isSelected(tournament.id)'
                                (change)='toggleTournament(tournament.id)'>
                    {{ tournament.name }}
                  </mat-checkbox>
                }
              </div>
            }
          </cdk-accordion-item>
        }
      </cdk-accordion>
    }
    @if (loading && !(categories?.length || topCategories?.length || sports?.length)) {
      @for (i of ' '.repeat(5).split(''); track $index) {
        <div class='ssc-square'></div>
      }
    }
  </div>
</div>
