/* istanbul ignore file */

import {
  Directive,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  NgZone,
  AfterViewInit,
  Inject,
  PLATFORM_ID,
  DestroyRef,
  inject,
} from '@angular/core';
import {
  Subject,
  timer,
  of as _of,
} from 'rxjs';
import { ScrollObservable } from './utils/scroll-observable';
import { OffsetResolverFactory } from './utils/offset-resolver';
import { PositionResolver } from './utils/position-resolver';
import { ElementBoundingPositions } from './utils/models';
import { WindowRuler } from './utils/viewport-ruler';
import { debounce, filter, mergeMap } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Idle } from '@scpc/modules/common/services/request-idle-callback';

@Directive({
  selector: '[scpInView]',
})
export class InViewDirective implements AfterViewInit {

  @Input()
  public trigger: Subject<any>;

  @Input()
  public scrollElement: HTMLElement;

  @Output()
  private inView: EventEmitter<any> = new EventEmitter();

  private _offset: Array<number | string> = [0, 0, 0, 0];
  private _viewPortOffset: Array<number | string> = [0, 0, 0, 0];
  private _throttle = 0;
  private _triggerOnInit = false;
  private destroyRef: DestroyRef = inject(DestroyRef);

  constructor(
    private readonly scrollObservable: ScrollObservable,
    private readonly elementRef: ElementRef,
    private readonly zone: NgZone,
    private readonly idle: Idle,
    private readonly windowRuler: WindowRuler,
    @Inject(PLATFORM_ID) private readonly platformId: string,
  ) {
  }

  @Input()
  public set triggerOnInit(triggerOnInit: boolean) {
    this._triggerOnInit = !!triggerOnInit;
  }

  @Input()
  public set offset(offset: Array<number | string> | number | string) {
    this._offset = OffsetResolverFactory.create(offset).normalizeOffset();
  }

  @Input()
  public set viewPortOffset(offset: Array<number | string> | number | string) {
    this._viewPortOffset = OffsetResolverFactory.create(offset).normalizeOffset();
  }

  @Input()
  public set throttle(throttle: number) {
    this._throttle = throttle;
  }

  public ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.scrollObservable
        .scrollObservableFor(this.scrollElement || window)
        .pipe(
          takeUntilDestroyed(this.destroyRef),
          debounce(() => timer(this._throttle)),
          filter(() => true),
          mergeMap(() => _of(this.getViewPortRuler())),
        )
        .subscribe((containersBounds: ElementBoundingPositions) =>
          this.handleOnScroll(containersBounds),
        );
      this.trigger?.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => {
        if (value) {
          this.handleOnScroll(this.getViewPortRuler());
        }
      });
      if (this._triggerOnInit) {
        this.idle.requestIdleCallback(() => this.handleOnScroll(this.getViewPortRuler()));
      }
    }
  }

  public handleOnScroll(containersBounds: ElementBoundingPositions) {
    const viewPortOffsetRect = PositionResolver.offsetRect(containersBounds, this._viewPortOffset);
    const elementOffsetRect = PositionResolver.offsetRect(PositionResolver.getBoundingClientRect(this.elementRef.nativeElement), this._offset);
    const isVisible =
      PositionResolver.isVisible(this.elementRef.nativeElement) &&
      PositionResolver.intersectRect(elementOffsetRect, viewPortOffsetRect);

    const output: any = { status: isVisible };

    if (!isVisible) {
      output.isClipped = false;
      output.isOutsideView = true;
      output.parts = { top: false, right: false, left: false, bottom: false };
      output.inViewPercentage = { vertical: 0, horizontal: 0 };
      this.zone.run(() => this.inView.emit(output));
    }

    if (!isVisible) {
      return;
    }

    const { isClipped, isOutsideView } = PositionResolver.clippedStatus(elementOffsetRect, viewPortOffsetRect);
    output.isClipped = isClipped;
    output.isOutsideView = isOutsideView;
    output.parts = PositionResolver.inViewParts(viewPortOffsetRect, elementOffsetRect);
    output.inViewPercentage = PositionResolver.inViewPercentage(viewPortOffsetRect, elementOffsetRect);
    this.zone.run(() => this.inView.emit(output));
  }

  private getViewPortRuler() {
    return this.scrollElement
      ? PositionResolver.getBoundingClientRect(this.scrollElement)
      : this.windowRuler.getWindowViewPortRuler();
  }

}
