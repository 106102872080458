<div class='scp-sports-event-video'>
  @if (state !== 'WATCH') {
    <div class='scp-sports-event-video__background' [class.fill]='state === "LOADING"'>
      @if (state !== 'LOADING') {
        <div class='scp-sports-event-video__background__shadow'></div>
        <img src='/assets/images/sports/video-background.jpeg' alt=''>
      }
    </div>
    <div class='scp-sports-event-video__content'>
      @switch (state) {
        @case ('LOADING') {
          <md-circular-progress indeterminate aria-label='Loading...'></md-circular-progress>
        }
        @case ('SIGN_IN') {
          <div class='scp-sports-event-video__content__title'>
            {{ 'ERROR.STREAM.SIGN_IN' | translate }}
          </div>
          <scp-btn [hasMarginTop]='false'
                   height='36'
                   type='16'
                   width='146'
                   text='TITLE.SIGN_IN_OR_SIGN_UP_2'
                   url='/sign-in' (click)='signIn.next()'>
          </scp-btn>
        }
        @case ('KYC') {
          <div class='scp-sports-event-video__content__title'>
            {{ 'ERROR.STREAM.KYC' | translate }}
          </div>
          <scp-btn [hasMarginTop]='false'
                   height='36'
                   type='1'
                   width='146'
                   text='VERIFY'
                   url='/account/profile/kyc'>
          </scp-btn>
        }
        @case ('KYC_IN_PROGRESS') {
          <div class='scp-sports-event-video__content__title'>
            {{ 'ERROR.STREAM.KYC_IN_PROGRESS' | translate }}
          </div>
        }
        @case ('LOW_BALANCE') {
          <div class='scp-sports-event-video__content__title'>
            {{ 'ERROR.STREAM.LOW_BALANCE' | translate: { minBalance: minBalance | formatMoney: true: false } }}
          </div>
          <scp-btn [hasMarginTop]='false'
                   height='36'
                   type='1'
                   width='146'
                   text='TITLE.DEPOSIT'
                   url='/account/wallet/deposits'>
          </scp-btn>
        }
        @case ('GEO_BLOCK') {
          <div class='scp-sports-event-video__content__title'>
            {{ 'ERROR.STREAM.GEO_BLOCK' | translate }}
          </div>
        }
        @case ('SERVICE_UNAVAILABLE') {
          <div class='scp-sports-event-video__content__title'>
            {{ 'ERROR.STREAM.SERVICE_UNAVAILABLE' | translate }}
          </div>
        }
      }
    </div>
  } @else {
    <div id='playercontainer'>
      <video>
        <source [src]='url' />
      </video>
    </div>
  }
</div>
