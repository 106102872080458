import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  NgZone,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {FilterComponent} from '@scpc/modules/sports/components/filter/filter.component';
import {take} from 'rxjs/operators';
import {ButtonComponent} from '@scpc/modules/common/components/button/button.component';
import {EventCategory, EventSport, EventTopCategory, EventType} from '@scpc/modules/sports/dto';
import {TranslateModule} from '@ngx-translate/core';
import {FilterRangeComponent} from '@scpc/modules/sports/components/filter-range/filter-range.component';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
  selector: 'scp-sports-filter-mobile',
  templateUrl: './filter-mobile.component.html',
  styleUrls: ['./filter-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FilterComponent,
    ButtonComponent,
    TranslateModule,
    FilterRangeComponent,
  ],
})
export class FilterMobileComponent {

  @Input()
  public type: EventType;

  @Input()
  public sports: EventSport[];

  @Input()
  public categories: EventCategory[];

  @Input()
  public topCategories: EventTopCategory[];

  @Input()
  public category: string;

  @Input()
  public sport: EventSport;

  @Input()
  public defaultCategory: string;

  @Input()
  public range: number;

  @Input()
  public hasLive: boolean;

  @Input()
  public hasPreMatch: boolean;

  @Input()
  public tournaments: string[];

  @Input()
  public loading = false;

  @Output()
  public selectionChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  @ViewChild('layoutRef', {read: TemplateRef, static: false})
  private readonly layoutRef: TemplateRef<HTMLElement>;

  @ViewChild('filterRef', {read: FilterComponent, static: false})
  private readonly filterComponent: FilterComponent;

  protected bottomSheetRef: MatBottomSheetRef;
  protected initialized = false;
  protected selected = 0;
  private destroyRef: DestroyRef = inject(DestroyRef);

  constructor(private readonly bottomSheet: MatBottomSheet,
              private readonly changeDetectorRef: ChangeDetectorRef,
              private readonly zone: NgZone) {
  }

  public async showFilter(): Promise<void> {
    this.initialized = true;
    this.bottomSheetRef = this.bottomSheet.open(this.layoutRef, {
      backdropClass: 'scp-backdrop',
      panelClass: ['vh-90'],
    });
    this.changeDetectorRef.detectChanges();
    this.selected = this.filterComponent.getSelection().length;
    this.filterComponent.selectionChange
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((selection: string[]) => {
        this.selected = selection.length;
        this.changeDetectorRef.markForCheck();
      });
    this.bottomSheetRef.afterDismissed()
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe((data?: { tournaments: string[], skip: boolean }) => {
        this.initialized = false;
        if (data && !data.skip) {
          this.selectionChange.emit(data.tournaments);
          this.changeDetectorRef.markForCheck();
        }
      });
  }

  /* istanbul ignore next */
  public update(): void {
    this.zone.run(() => this.changeDetectorRef.detectChanges());
  }

  public clear(): void {
    this.selectionChange.emit([]);
  }

}
