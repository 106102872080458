<div class='scp-sports-menu'>
  @if (!reachBeginning) {
    <div class='scp-sports-menu__arrow scp-sports-menu__arrow__left'>
      <button mat-icon-button
              (click)='swiper.slidePrev()'
              [attr.aria-label]="'BUTTON.PREV_PAGE' | translate">
        <img src='/assets/images/svg/arrow-2.svg' alt=''>
      </button>
    </div>
  }
  <div class='swiper swiper-sports'>
    <div class='swiper-wrapper'>
      @for (sport of sports; track trackBy(sport); let i = $index) {
        <button class='swiper-slide' (click)='navigate(i)' [disabled]='sport.slug === category'
                [class.active]='sport.slug === category'>
          <span class='name'>{{ sport.name }}@if (sport.liveCount) {
            <span class='badge'>{{ sport.liveCount }}</span>
          }</span>
        </button>
      }
    </div>
  </div>
  @if (!reachEnd) {
    <div class='scp-sports-menu__arrow scp-sports-menu__arrow__right'>
      <button mat-icon-button
              (click)='swiper.slideNext()'
              [attr.aria-label]="'BUTTON.NEXT_PAGE' | translate">
        <img src='/assets/images/svg/arrow-2.svg' alt=''>
      </button>
    </div>
  }
</div>
