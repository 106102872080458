import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { EventType } from '@scpc/modules/sports/dto';
import { Segment, SegmentedComponent } from '@scpc/modules/common/components/segmented/segmented.component';

@Component({
  selector: 'scp-sports-filter-range',
  templateUrl: './filter-range.component.html',
  styleUrls: ['./filter-range.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    SegmentedComponent,
  ],
})
export class FilterRangeComponent implements OnChanges {

  @Input()
  public type: EventType;

  @Input()
  public category: string;

  @Input()
  public defaultCategory: string;

  @Input()
  public range: number;

  @Input()
  public hasLive: boolean;

  @Input()
  public event = false;

  protected segments: Segment<string>[] = [];
  protected segment: Segment<string>;

  public ngOnChanges(changes: SimpleChanges) {
    if ('category' in changes || 'type' in changes || 'defaultCategory' in changes || 'event' in changes || 'hasLive' in changes || /* istanbul ignore next */ 'range' in changes) {
      this.segments = [];
      if (!this.category) {
        this.segments.push({
          label: 'Live',
          value: 'Live',
          routerLink: `/sports/live`,
          ariaLabel: 'Show all live events',
          custom: true,
        });
      } else if (this.category === 'live') {
        this.segments.push({
          label: 'Live',
          value: 'Live',
          routerLink: `/sports/live`,
          ariaLabel: 'Show all live events',
          custom: true,
        });
        this.segment = this.segments[0];
      } else if (this.category && this.hasLive) {
        this.segments.push({
          label: 'Live',
          value: 'Live',
          routerLink: `/sports/live/${this.category}`,
          ariaLabel: 'Show live events',
          custom: true,
        });
        if (this.type === 'live') {
          this.segment = this.segments[0];
        }
      }
      const category: string = (this.category === 'live' ? null : this.category) || this.defaultCategory;
      this.segments.push(...[
        {
          label: '1h',
          value: '1',
          routerLink: `/sports/prematch/${category}`,
          queryParams: { range: 1 },
          ariaLabel: 'Show prematch events (1h)',
        },
        {
          label: '3h',
          value: '3',
          routerLink: `/sports/prematch/${category}`,
          queryParams: { range: 3 },
          ariaLabel: 'Show prematch events (3h)',
        },
        {
          label: '12h',
          value: '12',
          routerLink: `/sports/prematch/${category}`,
          queryParams: { range: 12 },
          ariaLabel: 'Show prematch events (12h)',
        },
        {
          label: 'Prematch',
          value: 'Prematch',
          routerLink: `/sports/prematch/${category}`,
          minWidth: 80,
          ariaLabel: 'Show prematch events',
        },
      ]);

      if (this.category === 'live' || (this.category && this.type === 'live' && this.hasLive)) {
        this.segment = this.segments[0];
      } else if (this.range) {
        this.segment = this.segments.find(s => s.value === this.range.toString());
      } else if (this.type === 'prematch' && !this.range && !this.event) {
        this.segment = this.segments[this.segments.length - 1];
      } else {
        this.segment = null;
      }
    }
  }

}
