import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Event, EventCategory, EventTournament, EventMeta, EventSport } from './../../dto';
import { EventRowComponent } from '@scpc/modules/sports/components/event-row/event-row.component';
import { TournamentIconComponent } from '@scpc/modules/sports/components/tournament-icon/tournament-icon.component';
import {
  EventRowSkeletonComponent,
} from '@scpc/modules/sports/components/event-row-skeleton/event-row-skeleton.component';

@Component({
  selector: 'scp-sports-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TournamentIconComponent,
    EventRowComponent,
    EventRowSkeletonComponent,
  ],
})
export class EventsComponent {

  @Input()
  public items: {
    eventMeta: EventMeta
    event?: Event
  }[];

  @Input()
  public sport: EventSport;

  @Input()
  public category: EventCategory;

  @Input()
  public tournament: EventTournament;

  @Input()
  public isBrowser: boolean;

  @Input()
  public source: string;

  @ViewChildren(EventRowComponent)
  private rowsComponents: QueryList<EventRowComponent>;

  constructor(public readonly changeDetectorRef: ChangeDetectorRef,
              public readonly elementRef: ElementRef,
              private readonly zone: NgZone) {
  }

  public updateEvent(id: string): void {
    this.rowsComponents.find(item => item.event.id === id)?.update();
  }

  public update(): void {
    this.zone.run(() => this.changeDetectorRef.markForCheck());
  }

  protected trackBy(item: { event?: Event, eventMeta: EventMeta }): string {
    return item.eventMeta.id + !!item.event + item.event?.tournament.priority;
  }

}
