<div class='scp-sports-filter-mobile'>
  <div class='scp-sports-filter-mobile__compact with-actions'>
    <scp-sports-filter-range [category]='category'
                             [defaultCategory]='defaultCategory'
                             [hasLive]='hasLive'
                             [range]='range'
                             [type]='type'>
    </scp-sports-filter-range>
    <button class='scp-sports-filter-mobile__compact__filter' (click)='showFilter()' aria-label='Filter'>
      @if (category && (tournaments?.length || topCategories?.length)) {
        <span class='scp-sports-filter-mobile__compact__badge'>
          {{ tournaments?.length || topCategories.length }}
        </span>
      } @else if (!category && tournaments?.length) {
        <span class='scp-sports-filter-mobile__compact__badge'>
          {{ tournaments?.length }}
        </span>
      }
      <img alt='' src='/assets/images/sports/filter.svg' height='16' width='16'>
    </button>
    @if (tournaments?.length) {
      <button class='scp-sports-filter-mobile__compact_clear' (click)='clear()'
              aria-label='Clear filter'>
        <img alt='' src='/assets/images/sports/close.svg' height='10' width='10'>
      </button>
    } @else {
      <button (click)='showFilter()' aria-label='Filter'>
        <img alt='' src='/assets/images/sports/more.svg' height='5' width='10'>
      </button>
    }
  </div>
  <ng-template #layoutRef>
    <div class='scp-sports-filter-mobile__bottom_sheet'>
      <scp-sports-filter #filterRef
                         [type]='type'
                         [sport]='sport'
                         [category]='category'
                         [defaultCategory]='defaultCategory'
                         [range]='range'
                         [sports]='sports'
                         [categories]='categories'
                         [topCategories]='topCategories'
                         [hasLive]='hasLive'
                         [tournaments]='tournaments'
                         [border]='false'
                         [close]='true'
                         [loading]='loading'
                         [class.selected]='selected > 0'
                         (closed)='bottomSheetRef.dismiss({tournaments: [], skip: true })'></scp-sports-filter>
      @if (selected > 0) {
        <div class='scp-sports-filter-mobile__bottom_sheet__action'>
          <scp-btn [text]='"VIEW (" + selected+")"'
                   [type]='"1"'
                   [hasMarginTop]='false'
                   (click)='bottomSheetRef.dismiss({tournaments: filterRef.getSelection(), skip: false})'>
          </scp-btn>
        </div>
      }
    </div>
  </ng-template>
</div>
