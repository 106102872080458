import { EventMarket, EventOutcome } from '@scpc/modules/sports/dto';
import { compare } from 'natural-orderby';

export const MARKETS_BY_ODDS: string[] = ['38', '39', '40', '674', '675', '676', '677', '683', '684'];

export const STRING_SPECIFIERS: string[] = [
  'competitor', 'competitor1', 'competitor2', 'player', 'player1', 'player2', 'player3', 'team', 'variable_text', 'variant',
];

export function mergeMarkets(allMarkets: Map<string, EventMarket>, markets?: EventMarket[]): Map<string, EventMarket> {
  for (const market of (markets || [])) {
    if (allMarkets.has(market.marketUniqId)) {
      const oldMarket = allMarkets.get(market.marketUniqId);
      oldMarket.status = market.status;
      if (market.status !== 'Deactivated') {
        const outcomes = [];
        const oldOutcomes: Map<string, EventOutcome> = new Map<string, EventOutcome>(oldMarket.outcomes.map(o => [o.id, o]));
        const newOutcomes: Map<string, EventOutcome> = new Map<string, EventOutcome>(market.outcomes.map(o => [o.id, o]));
        for (const outcome of market.outcomes) {
          if (oldOutcomes.has(outcome.id)) {
            const oldOutcome = oldOutcomes.get(outcome.id);
            oldOutcome.active = outcome.active;
            if (oldOutcome.odds !== outcome.odds) {
              oldOutcome.oldOdds = oldOutcome.odds;
              oldOutcome.probability = outcome.probability;
              oldOutcome.odds = outcome.odds;
            } else {
              oldOutcome.oldOdds = undefined;
            }
            outcomes.push(oldOutcome);
          } else {
            outcomes.push(outcome);
          }
        }
        for (const outcome of oldMarket.outcomes) {
          if (!newOutcomes.has(outcome.id)) {
            outcome.active = false;
            outcomes.push(outcome);
          }
        }
        oldMarket.outcomes = outcomes;
      } else {
        allMarkets.delete(market.marketUniqId);
      }
    } else if (market.outcomes.length && market.status !== 'Deactivated') {
      allMarkets.set(market.marketUniqId, market);
    }
  }
  return allMarkets;
}


export function filterAndSortOutcomes(season: boolean, market: EventMarket): EventMarket {
  market.outcomes = market.outcomes
    .sort((a: EventOutcome, b: EventOutcome) => {
      if (season || MARKETS_BY_ODDS.includes(market.id)) {
        const diff = a.odds - b.odds;

        if (diff === 0) {
          return compare()(a.name, b.name);
        }

        return diff;
      }
      const a1 = Number(a.id.includes(':') ? a.id.split(':').slice(-1).pop() : a.id);
      const b1 = Number(b.id.includes(':') ? b.id.split(':').slice(-1).pop() : b.id);
      return a1 - b1;
    });
  return market;
}

export function reduceMarkets(allMarkets: Map<string, EventMarket>,
                              groupId: string,
                              season: boolean,
                              state: Map<string, boolean>,
                              clean: boolean = false): Map<string, {
  markets: EventMarket[],
  disabled: boolean,
  expanded: boolean
}> {
  const groups = new Map([...Array.from(allMarkets.values())
    .filter((market: EventMarket) => market.groups.findIndex(g => g.id === groupId) >= 0)
    .map((market: EventMarket) => {
      if (clean) {
        market.outcomes.forEach(o => o.oldOdds = undefined);
      }
      market.outcomes.forEach(o => o.combinedId = market.marketUniqId + '#' + o.id);
      return market;
    })
    .reduce((prev: Map<string, {
      markets: EventMarket[],
      disabled: boolean,
      expanded: boolean
    }>, current: EventMarket) => {
      const key = current.id + current.name + (current.mainParam.includes('sr:player') ? current.mainParam : '');
      const group = prev.get(key) || { markets: [], disabled: false, expanded: true };
      group.markets.push(filterAndSortOutcomes(season, current));
      return prev.set(key, group);
    }, new Map<string, { markets: EventMarket[], disabled: boolean, expanded: boolean }>)]
    .sort((a, b) => {
      /* istanbul ignore if */
      if (season) {
        return compare()(a[1].markets[0].name, b[1].markets[0].name);
      }
      let diff = b[1].markets[0].priority - a[1].markets[0].priority;
      if (diff === 0 && a[1].markets[0].id === b[1].markets[0].id && b[1].markets[0].specifiers.length === a[1].markets[0].specifiers.length) {
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < a[1].markets[0].specifiers.length; i++) {
          const s1 = a[1].markets[0].specifiers[i];
          const s2 = b[1].markets[0].specifiers[i];
          if (s1.type === s2.type) {
            if (s1.type === 'integer' || s1.type === 'decimal') {
              diff = (s1.value as number) - (s2.value as number);
              if (diff !== 0) {
                break;
              }
            } else if (s1.type === 'string' && STRING_SPECIFIERS.includes(s1.name)) {
              diff = compare()(s1.value, s2.value);
              if (diff !== 0) {
                break;
              }
            }
          }
        }
      }
      if (diff === 0) {
        return compare()(a[1].markets[0].name, b[1].markets[0].name);
      }
      return diff;
    }));

  let index = 0;

  for (const group of groups.entries()) {
    group[1].disabled = group[1].markets.every(m => m.status !== 'Active');
    /* istanbul ignore next */
    if (groupId === 'all' && !season) {
      group[1].expanded = (state.has(group[0]) && state.get(group[0])) || (!state.has(group[0]) && index <= 20);
    } else {
      group[1].expanded = !state.has(group[0]) || state.get(group[0]);
    }
    if (group[1].markets.length > 1) {
      group[1].markets.sort((a, b): number => {
        const s1 = a.specifiers.find(s => s.name === 'total');
        const s2 = b.specifiers.find(s => s.name === 'total');
        if (s1 && s2) {
          return (s1.value as number) - (s2.value as number);
        }
        const s3 = a.specifiers.find(s => s.name === 'hcp');
        const s4 = b.specifiers.find(s => s.name === 'hcp');
        if (s3 && s4) {
          if (s3.value.toString().includes(':')) {
            const p1 = (s3.value as string).split(':');
            const p2 = (s4.value as string).split(':');
            const diff = Number(p2[0]) - Number(p1[0]);
            if (diff === 0) {
              return Number(p1[1]) - Number(p2[1]);
            }
            return diff;
          } else {
            return (s3.value as number) - (s4.value as number);
          }
        }
        /* istanbul ignore next */
        return 0;
      });
    }
    index++;
  }

  return groups;
}

export function formatCorrectScoreOutcomes(market: EventMarket): {
  a: EventOutcome[],
  b: EventOutcome[],
  c: EventOutcome[]
} {
  return {
    a: filterCorrectScoreOutcomes(market, '1'),
    b: filterCorrectScoreOutcomes(market, 'x'),
    c: filterCorrectScoreOutcomes(market, '2'),
  };
}

function filterCorrectScoreOutcomes(market: EventMarket, type: '1' | 'x' | '2'): EventOutcome[] {
  return market.outcomes
    .filter((outcome: EventOutcome): boolean => {
      const parts: string[] = outcome.name.split(':');
      /* istanbul ignore next */
      const diff: number = (parts.length === 1 ? 0 : Number(parts[0])) - (parts.length === 1 ? 0 : Number(parts[1]));
      const t: 'x' | '1' | '2' = diff === 0 ? 'x' : diff > 0 ? '1' : '2';
      return t === type;
    });
}
